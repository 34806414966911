import styled from "@emotion/styled";
import React from "react";
import {whatsapp} from 'react-icons-kit/fa/whatsapp'
import Icon from "react-icons-kit";
import { Link } from "gatsby";

// a container that spans the whole window:
const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: none;
`;

const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #25D366;
  color: white;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  pointer-events: all;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translateY(-3px);
    background-color: #36E477;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  }
`;

const Whatsapp = (props) => (
  <Container>
    <Button to="https://wa.me/message/Q7ZUR6IIZARQP1">
      <Icon icon={whatsapp} size={32} />
    </Button>
  </Container>
);

export default Whatsapp;
