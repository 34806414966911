import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Box } from '@rebass/grid'

const Card = styled(Box)`
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 20px;
	height: ${props => props.collapsed ? `auto` : `100%`};
	border-radius: 12px;
	background-color: white;
	box-shadow: 0 0 20px rgba(0, 0, 0, .05);
	${props => (props.blue && css`
		border-top: 3px solid #aca1e1;
	`)}
	${props => (props.purple && css`
		border-top: 3px solid #bd86c9;
	`)}
	${props => (props.red && css`
		border-top: 3px solid #e56c91;
	`)}
	background-color: ${props => (props.bg)};
	@media (min-width: 425px) {
		padding: 40px;
	}
`;

const SolidCard = styled(Card)`
	color: white;
	background: linear-gradient(10deg, #a6113c 0%, #80176a 100%);
	overflow: hidden;
`;

export default Card;
export { SolidCard };
