import base from './base'

export const company = props => base({
	name: 'company',
	getAttributes: () => ({
		'@context': 'http://schema.org',
		'@type': 'RealEstateAgent',
		'@id': props.siteUrl,
		'url': props.siteUrl,
		...props.name &&  { 'name': props.name },
		...props.image && { 'image': props.image },
		...props.phone && { 'telephone': props.phone },
		'address': {
			'@type': 'PostalAddress',
			'addressCountry': 'DE',
			...props.streetAddress &&  { 'streetAddress': props.streetAddress },
			...props.postalCode &&     { 'postalCode': props.postalCode },
			...props.city &&           { 'addressLocality': props.city },
			...props.state &&          { 'addressRegion': props.state }
		},
		'location': {
			'@type': 'Place',
			...props.geoRadius && { 'geo': {
				'@type': 'GeoCircle',
				'geoRadius': props.geoRadius
			}}
		},
		...props.aggregateRating && { 'aggregateRating': {
			'@type': 'AggregateRating',
			'ratingValue': props.aggregateRating.rating,
			'ratingCount': props.aggregateRating.count
		}},
		...props.reviews && { 'review': props.reviews.map(review => ({
			'@type': 'Review',
			'author': review.author,
			'datePublished': review.date,
			'reviewBody': review.text,
			'reviewRating': {
				'@type': 'Rating',
				'ratingValue': review.rating
			}
		}))},
		...props.openingHours && { 'openingHours': [ props.openingHours ] },
		...props.priceRange &&   { 'priceRange': [ props.priceRange ] }
	})
})
