import React from "react";
import { css } from "@emotion/react";

import { ReactComponent as SvgLogo } from "./kranich-logo.svg";
import { ReactComponent as SvgSymbol } from "./kranich-symbol.svg";
import { ReactComponent as SvgLogoWhite } from "./kranich-logo-white.svg";
import { ReactComponent as SvgSymbolWhite } from "./kranich-symbol-white.svg";

const symbolStyle = css`
  max-width: 135px;
  height: auto;
  padding: 4px 0;
`;

const logoStyle = css`
  width: 100%;
  max-width: 220px;
  height: auto;
  padding: 8px 0;
`;

const Logo = (props) =>
  props.simple ? (
    props.white ? (
      <SvgSymbolWhite css={symbolStyle} />
    ) : (
      <SvgSymbol css={symbolStyle} />
    )
  ) : props.white ? (
    <SvgLogoWhite css={logoStyle} />
  ) : (
    <SvgLogo css={logoStyle} />
  );

export default Logo;
