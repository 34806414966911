import React from "react";
import styled from "@emotion/styled";

const Container = styled.address`
  font-style: normal;
  text-align: center;
`;

const Address = () => (
  <Container>
    Ahornallee 1 | 99428 Weimar
    <br />
    Große Gebind 22b | 99448 Kranichfeld
    <br />
    Breitscheidstraße 92 | 07407 Rudolstadt
    <br />
    <br />
    T. <a href="tel:036450449933">036450 / 44 99 33</a>
    <br />
    F. <a href="tel:036450449934">036450 / 44 99 34</a>
    <br />
    M. <a href="tel:01743217733">0174 / 32 1 77 33</a>
    <br />
    <br />
    <a href="mailto:info@immobilien-kranichfeld.de">
      info@immobilien-kranichfeld.de
    </a>
    <br />
    <a href="https://www.immobilien-kranichfeld.de">
      www.immobilien-kranichfeld.de
    </a>
  </Container>
);

export default Address;
