import React from "react";

import { StaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { company } from "../../../../shared/helpers/schema";

const CompanySchema = ({
  address = {
    streetAddress: "Große Gebind 22b",
    phone: "036450 44 99 33",
    postalCode: "99448",
    city: "Kranichfeld",
  },
}) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
          }
        }
        founderImage: imageSharp(
          fixed: { originalName: { regex: "/linda_2/" } }
        ) {
          fixed {
            src
          }
        }
      }
    `}
    render={(data) => (
      <Helmet
        script={[
          company({
            siteUrl: data.site.siteMetadata.siteUrl,
            siteTitle: data.site.siteMetadata.title,
            name: "Kranich Immobilien",
            image: data.site.siteMetadata.siteUrl + data.founderImage.fixed.src,
            phone: address.phone,
            streetAddress: address.streetAddress,
            postalCode: address.postalCode,
            city: address.city,
            state: "Thüringen",
            geoRadius: "50 km",
            openingHours: "Mo-Fr 09:00-21:00",
            priceRange: "$$",
          }),
        ]}
      />
    )}
  />
);
export default CompanySchema;
